export const isUrl = (url: string): boolean => {
  try {
    new URL(url);
    return true;
  } catch (_) {
    return false;
  }
};

export const parseQueryString = (query: string) =>
  (query.startsWith('?') ? query.slice(1) : query)
    .split('&')
    .filter((param) => param !== '')
    .map((param) => param.split('='))
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value ?? '' }), {});

export const mergeQueryParams = (url: string, params: Record<string, string>) => {
  if (!Object.keys(params).length) return url;
  const urlObject = new URL(url);
  const searchParams = new URLSearchParams(urlObject.search);
  Object.entries(params).forEach(([key, value]) => {
    urlObject.searchParams.delete(key);
    searchParams.set(key, value);
  });

  return `${urlObject.origin}${urlObject.pathname}?${searchParams.toString()}`;
};
